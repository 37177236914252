import { Icon, RangeSlider } from "@shopify/polaris";
import { HideMinor, ViewMinor } from "@shopify/polaris-icons";
import { ICustomerPortalThemeForm, ThemeVersion } from "@smartrr/shared/entities/CustomerPortalTheme";
import { FormikErrors } from "formik";
import React from "react";
import styled from "styled-components";

import { ColorPicker } from "../components/ColorPicker";
import { ColorPickerContainer } from "../styles";

export const ColorValues = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  div {
    margin-bottom: 5px;
  }
`;

export const RangeSliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const RangeSliderWrapper = styled.div`
  margin-top: 7px;
  width: 217px;

  .Polaris-Label__Text {
    font-family: "SF Pro Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

export const TextWrapper = styled.span`
  align-items: flex-end;
  display: flex;
  margin-left: 10px;
`;

export const FontWrapper = styled.div`
  > div {
    margin-bottom: 20px;
  }
`;
export const AccordionItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-right: -10px;
  padding-bottom: 8px;
  span {
    margin-right: 5px;
    margin-top: 10px;
  }
`;

export const EmbeddedWrapper = styled.div`
  align-items: center;

  display: flex;
  justify-content: space-between;
`;

export const themeSelections = [
  {
    label: "Legacy theme",
    disabled: false,
    value: ThemeVersion.LEGACY,
  },
  {
    label: "Modern theme",
    disabled: false,
    value: ThemeVersion.MODERN,
  },
];

export const ColorPickerLabel = styled.div`
  font-family: "SF Pro Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const ColorPickerValue = styled.div`
  font-family: "SF Pro Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const Eye: React.FC<{
  value: boolean;
  valueName: string;
  setThemeFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ICustomerPortalThemeForm>>;
}> = ({ value, valueName, setThemeFieldValue }) => {
  // splitting valueName from `modernThemeSettings.sections.{VALUE}.display` ⇉ `{VALUE}`
  const splitValueNames = valueName.split(".");
  const testIdName = splitValueNames[splitValueNames.length - 2];

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => setThemeFieldValue(valueName, !value)}
      data-testid={`theming-sidebar-${testIdName}-${value ? "visible" : "hidden"}`}
    >
      {value ? <Icon source={ViewMinor} color="base" /> : <Icon source={HideMinor} color="subdued" />}
    </div>
  );
};

export const ThemingColorPicker: React.FC<{
  value: string;
  title: string;
  valueName: string;
  setThemeFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ICustomerPortalThemeForm>>;
}> = ({ value, title, valueName, setThemeFieldValue }) => {
  return (
    <ColorPickerContainer>
      <ColorPicker defaultColor={value} onChange={color => setThemeFieldValue(valueName, color)} />
      <ColorValues>
        <ColorPickerLabel>{title}</ColorPickerLabel>
        <ColorPickerValue>{value}</ColorPickerValue>
      </ColorValues>
    </ColorPickerContainer>
  );
};

export const ThemingRangeSlider: React.FC<{
  value: number;
  title: string;
  valueName: string;
  setThemeFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ICustomerPortalThemeForm>>;
  min?: number;
  max?: number;
}> = ({ value, title, valueName, setThemeFieldValue, min, max }) => {
  return (
    <RangeSliderContainer>
      <RangeSliderWrapper>
        <RangeSlider
          min={min ? min : undefined}
          max={max ? max : undefined}
          label={title}
          value={value}
          onChange={sliderValue => setThemeFieldValue(valueName, sliderValue)}
          output
        />
      </RangeSliderWrapper>
      <TextWrapper>{`${value}px`}</TextWrapper>
    </RangeSliderContainer>
  );
};
